import React, { useEffect } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import { AutoAwesomeOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { updateSelectedFilterItems } from 'state/ducks/filters/actions';
import {
  setBatchId,
  setBatchJobs,
  setIsSelectAllPages,
  setIsSelectAllPagesLoading,
  setSelectAllPageCount,
  setSelectedEditField,
} from 'state/ducks/staffingWorkPage/actions';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTheme } from '@mui/material/styles';
import { setAutoStaffing } from '../../state/ducks/dialog/actions';
import AutoStaffingDialog from '../Dialog/AutoStaffingDialog';
import { autoStaffingDialogs } from './utils';

function BatchModeActions() {
  const centralMode = useSelector(
    (state) => state.filters.selectedFilterItems.centralMode
  );
  const currentMode = Array.from(centralMode.values())[0];
  const groups = useSelector((state) => state.access.groups);
  const homeStoreOnlyOption = {
    auto_home_store_only: {
      title: 'AI Assist - Home Store',
      description: 'Staff using AI for in-banner teammates, at home store',
    },
  };
  const geographyOption = {
    auto_beyond_home_store: {
      title: 'AI Assist - Geography',
      description: 'Staff using AI for in-banner teammates, by distance',
    },
  };
  const bannerSharingOption = {
    auto_banner_sharing: {
      title: 'AI Assist - Banner Share',
      description: 'Staff using AI to find best fit across different banners',
    },
  };
  const options = {
    ...(groups.includes('auto_staff_home_store_only')
      ? homeStoreOnlyOption
      : {}),
    ...(groups.includes('auto_staff_beyond_home_store') ? geographyOption : {}),
    ...(groups.includes('auto_staff_banner_sharing')
      ? bannerSharingOption
      : {}),
  };
  const numberOfOptions = Object.entries(options).length;
  const [open, setOpen] = React.useState(false);
  const indexOfCurrentMode = Object.keys(options).indexOf(currentMode);
  const currentModeIndex = indexOfCurrentMode === -1 ? 0 : indexOfCurrentMode;
  const anchorRef = React.useRef(null);
  const assignmentsCount = useSelector(
    (state) => state.staffingWorkPage.batch.batchJobs
  ).length;
  const batchId = useSelector((state) => state.staffingWorkPage.batch.batchId);
  const disableEditButton = assignmentsCount === 0 && !batchId;
  const isSingleModeOn = currentMode === 'single';
  const isBatchModeOn = currentMode === 'batch';
  const isAutoModeOn = currentMode.startsWith('auto');
  const modeButtonText = {
    single: '',
    batch: 'EDIT',
    auto_home_store_only: 'STAFF',
    auto_beyond_home_store: 'STAFF',
    auto_banner_sharing: 'STAFF',
  };
  const dispatch = useDispatch();
  const theme = useTheme();

  const titleToId = (title) =>
    title.replaceAll(' - ', '-').replaceAll(' ', '-').toLowerCase();

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleSelectCentralMode = (filterOption) => {
    if (currentMode !== filterOption) {
      dispatch(
        updateSelectedFilterItems({
          centralMode: new Map([[filterOption, filterOption]]),
        })
      );
      dispatch(setBatchJobs([]));
      dispatch(setIsSelectAllPages(false));
      dispatch(setIsSelectAllPagesLoading(false));
      dispatch(setBatchId(null));
      dispatch(setSelectAllPageCount(0));
    }
  };

  useEffect(() => {
    if (numberOfOptions === 0 && isAutoModeOn) {
      handleSelectCentralMode('single');
    }
  }, [options, centralMode]);

  useEffect(() => {
    if (numberOfOptions > 0 && isAutoModeOn) {
      dispatch(setAutoStaffing(autoStaffingDialogs[currentMode]));
    }
  }, [centralMode]);

  return (
    <Box
      css={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <ButtonGroup size="small" ref={anchorRef} variant="outlined">
        <Button
          selected={isSingleModeOn}
          value={isSingleModeOn}
          id="single_mode"
          onClick={() => handleSelectCentralMode('single')}
          className={isSingleModeOn ? 'selected' : undefined}
        >
          Single
        </Button>
        <Button
          selected={isBatchModeOn}
          value={isBatchModeOn}
          id="batch_mode"
          onClick={() => handleSelectCentralMode('batch')}
          className={isBatchModeOn ? 'selected' : undefined}
        >
          Batch
        </Button>
        {numberOfOptions > 0 && (
          <Button
            selected={isAutoModeOn}
            value={isAutoModeOn}
            id="auto_mode"
            className={isAutoModeOn ? 'selected' : undefined}
            onClick={() => {
              handleSelectCentralMode(Object.keys(options)[currentModeIndex]);
            }}
          >
            {Object.values(options)[currentModeIndex].title}
            <AutoAwesomeOutlined
              css={{
                marginLeft: '4px',
                width: '18.33px',
                height: '18.33px',
              }}
            />
          </Button>
        )}
        {numberOfOptions > 0 && (
          <Button
            onClick={() => {
              setOpen((prevOpen) => !prevOpen);
            }}
            value="arrow-down"
            id="auto-mode-arrow-down"
            selected={false}
            aria-label="Auto Mode Options"
          >
            <ArrowDropDownIcon />
          </Button>
        )}
      </ButtonGroup>
      <Popper
        css={{ zIndex: theme.zIndex.drawer + 1 }}
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  id="split-button-menu"
                  css={{
                    padding: 0,
                  }}
                  autoFocusItem
                >
                  {Object.entries(options).map(([key, option]) => (
                    <MenuItem
                      id={titleToId(option.title)}
                      key={option.title}
                      onClick={() => {
                        setOpen(false);
                        handleSelectCentralMode(key);
                      }}
                      css={{
                        '&:not(:last-child)': {
                          borderBottom: `1px solid ${theme.palette.divider}`,
                        },
                      }}
                    >
                      <Box>
                        <Typography variant="body2">{option.title}</Typography>
                        <Typography
                          variant="body2"
                          css={{
                            color: theme.palette.text.secondary,
                            width: '200px',
                            textWrap: 'wrap',
                          }}
                        >
                          {option.description}
                        </Typography>
                      </Box>
                      <AutoAwesomeOutlined
                        css={{
                          width: '22px',
                          height: '22px',
                          padding: '14px 16px 14px 0',
                        }}
                      />
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {(isBatchModeOn || isAutoModeOn) && (
        <Button
          disabled={disableEditButton}
          variant="contained"
          id="batch_edit"
          size="medium"
          css={{ marginLeft: '24px' }}
          onClick={() => {
            dispatch(setSelectedEditField('none'));
          }}
        >
          {modeButtonText[currentMode]}
        </Button>
      )}
      <AutoStaffingDialog />
    </Box>
  );
}

export default BatchModeActions;
