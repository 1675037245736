/* eslint-disable camelcase */
import React, { useState } from 'react';
import Chip from '@mui/material/Chip';
import { Popover, Stack, Typography } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { Box } from '@mui/system';
import { StatusChipWrapper } from '../style';
import { statusAttributesMapping } from '../constants';
import { getJobDeclineReason } from '../../../services/jobs';
import { determineDeclineReasonText } from '../utils';
import LoadingSpinner from '../../AssociateDetails/components/LoadingSpinner';

function JobStatusChip({ jobId, status, jobDetails, variant }) {
  const [declineReason, setDeclineReason] = useState(null);
  const [isDeclineReasonLoading, setIsDeclineReasonLoading] = useState(true);
  const [anchorElement, setAnchorElement] = useState(null);
  const open = Boolean(anchorElement);
  const handlePopoverOpen = (event) => {
    setAnchorElement(event.currentTarget);
    if (!declineReason && !open) {
      setIsDeclineReasonLoading(true);
      getJobDeclineReason(jobId)
        .then(({ decline_reason_text, decline_reason, user }) => {
          const declineReasonText = determineDeclineReasonText(
            decline_reason_text,
            decline_reason
          );
          setDeclineReason(`${user}: ${declineReasonText}`);
          setIsDeclineReasonLoading(false);
        })
        .catch(() => {
          setDeclineReason('Error retrieving decline reason');
          setIsDeclineReasonLoading(false);
        });
    }
  };

  const handlePopoverClose = () => {
    setAnchorElement(null);
  };

  if (statusAttributesMapping[status]) {
    const { color, label } = statusAttributesMapping[status];
    if (label === statusAttributesMapping.jyver_replied_no.label) {
      return (
        <StatusChipWrapper
          jobDetails={jobDetails}
          aria-owns={open ? 'decline-reason-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <Chip
            label={
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <div>{label}</div>
                <InfoOutlined id="decline-tooltip" fontSize="inherit" />
              </Stack>
            }
            id={`job_status_chip_${label}`}
            variant={variant}
            size="small"
            color={color}
          />
          <Popover
            id="decline-reason-popover"
            css={{ pointerEvents: 'none' }}
            open={open}
            anchorEl={anchorElement}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            {isDeclineReasonLoading ? (
              <Box css={{ padding: '8px' }}>
                <LoadingSpinner id="decline-reason-loading" />
              </Box>
            ) : (
              <Typography
                css={{
                  padding: '8px',
                  whiteSpace: 'pre-wrap',
                  overFlowWrap: 'break-word',
                  maxWidth: '360px',
                }}
              >
                {declineReason}
              </Typography>
            )}
          </Popover>
        </StatusChipWrapper>
      );
    }
    return (
      <StatusChipWrapper jobDetails={jobDetails}>
        <Chip
          label={label}
          id={`job_status_chip_${label}`}
          variant={variant}
          size="small"
          color={color}
        />
      </StatusChipWrapper>
    );
  }
  return '';
}

export default JobStatusChip;
