/* eslint-disable camelcase */
import { format, getHours, getMinutes, isSameDay } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export function convertMinsToHrsMins(
  mins,
  roundToHour = false,
  abbreviateSuffixOnLongerStrings = false
) {
  const h = Math.floor(mins / 60);
  const m = mins % 60;

  let hPhrase = h === 1 ? ' hour' : ' hours';
  let mPhrase = m === 1 ? ' minute' : ' minutes';

  if (h <= 0) {
    return `${m}${mPhrase}`;
  }

  if (h >= 1 && m <= 0) {
    return `${h}${hPhrase}`;
  }

  if (h >= 1 && roundToHour) {
    const roundedH = Math.round(mins / 60) % 24;
    return `~${roundedH}${hPhrase}`;
  }

  if (abbreviateSuffixOnLongerStrings) {
    hPhrase = 'h';
    mPhrase = 'm';
  }

  return `${h}${hPhrase}${
    abbreviateSuffixOnLongerStrings ? ' ' : ' and '
  }${m}${mPhrase}`;
}

export function scrollIntoView(expanded, elementId, timeoutMs = 300) {
  const scrollToDiv = document.getElementById(elementId);
  if (expanded && scrollToDiv) {
    setTimeout(() => {
      scrollToDiv.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }, timeoutMs);
  }
}

export const formatErrorFromSubsystem = (
  message,
  error,
  externalIdentifier,
  homeSystem
) =>
  `${externalIdentifier}-${homeSystem} - ${message}${error ? `: ${error}` : '.'}`;

export function capitalizeWordsInAllCaps(str) {
  const lowercaseStr = str.toLowerCase();
  const wordsArray = lowercaseStr.split(' ');
  const capitalizedWords = wordsArray.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  return capitalizedWords.join(' ');
}

export function concatenateAndPrepend(prefix, sortableFields, staticFields) {
  if (sortableFields.length === 0) {
    return staticFields.join(',');
  }
  const modifiedSortableFields = sortableFields.map(
    (item) => `${prefix}${item}`
  );
  const concatenatedArray = [...modifiedSortableFields, ...staticFields];
  return concatenatedArray.join(',');
}

export function capitalizeFirstLetter(str) {
  return str
    .split('_')
    .map((item) => {
      const [firstLetter, ...rest] = item.split('');
      return [firstLetter.toUpperCase(), ...rest].join('');
    })
    .join(' ');
}

export function stringify(object) {
  return object.reduce(
    (accumulator, [key, value], index) =>
      `${accumulator}${key}=${value}${object.length - 1 !== index ? '&' : ''}`,
    ''
  );
}

export function formatTimeRange(timeRange) {
  const { start_hour, start_minute, end_hour, end_minute } = timeRange;

  if (
    start_hour === 0 &&
    start_minute === 0 &&
    end_hour === 23 &&
    end_minute === 59
  ) {
    return 'Available all day';
  }
  const startTime = format(
    new Date(0, 0, 0, start_hour, start_minute),
    'h:mm a'
  );
  const endTime = format(new Date(0, 0, 0, end_hour, end_minute), 'h:mm a');
  return `${startTime} - ${endTime}`;
}

export function transformAvailabilityData(current_availability) {
  const daily_availabilities = current_availability.daily_availability.reduce(
    (acc, curr) => {
      const existingDay = acc.find((day) => day.day === curr.day);

      if (existingDay) {
        return acc.map((day) =>
          day.day === curr.day
            ? {
                ...day,
                time_ranges: [
                  ...day.time_ranges,
                  {
                    start_hour: curr.start_hour,
                    start_minute: curr.start_minute,
                    end_hour: curr.end_hour,
                    end_minute: curr.end_minute,
                  },
                ],
              }
            : day
        );
      }
      return [
        ...acc,
        {
          day: curr.day,
          id: curr.id,
          time_ranges: [
            {
              start_hour: curr.start_hour,
              start_minute: curr.start_minute,
              end_hour: curr.end_hour,
              end_minute: curr.end_minute,
            },
          ],
        },
      ];
    },
    []
  );

  const missingDays = Array.from({ length: 7 }, (_, day) => day)
    .filter(
      (day) =>
        !daily_availabilities.some(
          (daily_availability) => daily_availability.day === day
        )
    )
    .map((day) => ({ day, id: day, time_ranges: [] }));

  const dailyAvailability = missingDays
    .concat(daily_availabilities)
    .sort((a, b) => a.day - b.day);

  return dailyAvailability;
}

export function transformTimeOffData(start_datetime, end_datetime, timezone) {
  const startZonedTime = utcToZonedTime(start_datetime, timezone);
  const endZonedTime = utcToZonedTime(end_datetime, timezone);

  const isStartOfDay =
    getHours(startZonedTime) === 0 && getMinutes(startZonedTime) === 0;
  const isEndOfDay =
    getHours(endZonedTime) === 23 && getMinutes(endZonedTime) === 59;

  const dateFormat = 'M/d/yyyy';
  const timeFormat = 'h:mm a';

  if (isSameDay(startZonedTime, endZonedTime)) {
    if (isStartOfDay && isEndOfDay) {
      return `${format(startZonedTime, dateFormat)}`;
    }

    return `${format(
      startZonedTime,
      `${dateFormat}, ${timeFormat}`
    )} - ${format(endZonedTime, timeFormat)}`;
  }

  if (isStartOfDay && isEndOfDay) {
    return `${format(startZonedTime, dateFormat)} - ${format(endZonedTime, dateFormat)}`;
  }

  if (isStartOfDay) {
    return `${format(startZonedTime, dateFormat)} - ${format(endZonedTime, `${dateFormat}, ${timeFormat}`)}`;
  }

  if (isEndOfDay) {
    return `${format(startZonedTime, `${dateFormat}, ${timeFormat}`)} - ${format(endZonedTime, dateFormat)}`;
  }

  return `${format(startZonedTime, `${dateFormat}, ${timeFormat}`)} - ${format(endZonedTime, `${dateFormat}, ${timeFormat}`)}`;
}

export const determineMatchedAssociateAssignButtonText = (batchJobs) =>
  batchJobs.length === 1 ? 'Select' : 'Assign';

export const formatNumberWithCommas = (number) =>
  parseInt(number, 10).toLocaleString();
