import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ClearFiltersCounter from './ClearFiltersCounter';
import { determineNumberOfFilters } from '../utils';
import {
  updateSelectedFilterItems,
  updateFilterQueries,
  updateFilterModes,
} from '../../../state/ducks/filters/actions';
import {
  DEFAULT_FILTER_QUERIES,
  DEFAULT_FILTER_MODES,
  DEFAULT_SELECTED_FILTERS,
} from '../constants';

function NumberOfSelectedFiltersTag({ sectionKeys }) {
  const selectedFilterItems = useSelector(
    (state) => state.filters.selectedFilterItems
  );
  const sectionSelectedFilters = Object.entries(selectedFilterItems).reduce(
    (accumulator, [key, value]) =>
      sectionKeys.includes(key)
        ? {
            ...accumulator,
            [key]: value,
          }
        : accumulator,
    {}
  );
  const numberOfSelectedFilters = determineNumberOfFilters(
    sectionSelectedFilters
  );
  const dispatch = useDispatch();

  if (numberOfSelectedFilters > 0) {
    return (
      <ClearFiltersCounter
        sectionKeys={sectionKeys}
        numberOfActiveFilters={numberOfSelectedFilters}
        onClear={() => {
          dispatch(
            updateSelectedFilterItems(
              sectionKeys.reduce(
                (accumulator, sectionKey) => ({
                  ...accumulator,
                  [sectionKey]: DEFAULT_SELECTED_FILTERS[sectionKey],
                }),
                {}
              )
            )
          );
          dispatch(
            updateFilterQueries(
              sectionKeys.reduce(
                (accumulator, sectionKey) => ({
                  ...accumulator,
                  [sectionKey]: DEFAULT_FILTER_QUERIES[sectionKey],
                }),
                {}
              )
            )
          );
          dispatch(
            updateFilterModes(
              sectionKeys.reduce(
                (accumulator, sectionKey) => ({
                  ...accumulator,
                  [sectionKey]: DEFAULT_FILTER_MODES[sectionKey],
                }),
                {}
              )
            )
          );
        }}
      />
    );
  }

  return null;
}

export default NumberOfSelectedFiltersTag;
