import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import { determineUpdatedFilters } from 'containers/Filters/utils';
import { EmptyStateWrapper } from '../../style';
import * as filterOperations from '../../../../state/ducks/filters/operations';
import SearchInput from '../SearchInput';
import {
  updateFilterQueries,
  updateSelectedFilterItems,
} from '../../../../state/ducks/filters/actions';
import FilterModeButtonGroup from '../FilterModeButtonGroup';

function FilterAccordionDetails(props) {
  const {
    sectionKey,
    parseData,
    endpoint,
    determineSearchParams,
    params,
    apiSearchKey,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isErrored, setIsErrored] = useState(false);
  const [isDebouncing, setIsDebouncing] = useState(false);
  const [throttleTimeout, setThrottleTimeout] = useState(null);
  const query = useSelector((state) => state.filters.filterQueries[sectionKey]);
  const dispatch = useDispatch();
  const sectionFilterItems = useSelector(
    (state) => state.filters.filterItems[sectionKey]
  );
  const {
    [sectionKey]: sectionSelectedFilterItems,
    ...otherSelectedFilterItems
  } = useSelector((state) => state.filters.selectedFilterItems);
  function fetchFilters(searchQuery) {
    setIsLoading(true);
    dispatch(
      filterOperations.fetchFilterItems({
        sectionKey,
        searchQuery,
        apiSearchKey,
        determineSearchParams,
        endpoint,
        params,
        parseData,
      })
    )
      .then(() => {
        setIsLoading(false);
        setIsErrored(false);
      })
      .catch(() => {
        setIsLoading(false);
        setIsErrored(true);
      });
  }
  const fetchFiltersDebounce = (searchQuery) => {
    if (searchQuery) {
      setIsDebouncing(true);
      clearTimeout(throttleTimeout);
      setThrottleTimeout(
        setTimeout(() => {
          setIsDebouncing(false);
          fetchFilters(searchQuery);
        }, 300)
      );
    } else {
      fetchFilters(searchQuery);
    }
  };

  useDeepCompareEffect(() => {
    fetchFilters(query);
  }, [otherSelectedFilterItems, query === '']);

  const handleRenderItems = () => {
    if (isLoading && !apiSearchKey) {
      return (
        <Box
          css={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginTop: 8,
            marginBottom: 16,
          }}
          id="filter-loading"
        >
          <CircularProgress color="primary" size="32px" />
        </Box>
      );
    }

    if (isErrored) {
      return (
        <EmptyStateWrapper>
          Filter options failed to load.
          <Button
            id={`filter-section-${sectionKey}-retry-btn`}
            variant="outlined"
            onClick={() => {
              fetchFilters(query);
            }}
            css={{
              marginTop: '12px',
            }}
          >
            Retry
          </Button>
        </EmptyStateWrapper>
      );
    }

    const allFilterItems = new Map([
      ...sectionFilterItems,
      ...sectionSelectedFilterItems,
    ]);
    if (!isDebouncing && !isLoading && isEmpty(allFilterItems)) {
      return (
        <EmptyStateWrapper id="filter-no-result">
          No results found
        </EmptyStateWrapper>
      );
    }

    const selectedFilterNames = Array.from(
      sectionSelectedFilterItems.values()
    ).sort();
    return Array.from(allFilterItems)
      .sort(([, first], [, second]) =>
        selectedFilterNames.includes(first) &&
        !selectedFilterNames.includes(second)
          ? -1
          : 0
      )
      .map(([id, name]) => {
        const isChecked = Array.from(
          sectionSelectedFilterItems.keys()
        ).includes(id);
        return (
          <FormControlLabel
            id={`filter-item-${sectionKey}-${id}`}
            key={`filter-item-${sectionKey}-${id}`}
            label={
              <Typography variant="body2" css={{ paddingTop: '11px' }}>
                {name}
              </Typography>
            }
            checked={isChecked}
            control={
              <Checkbox
                onChange={({ target: { checked } }) => {
                  dispatch(
                    updateSelectedFilterItems({
                      [sectionKey]: determineUpdatedFilters(
                        sectionSelectedFilterItems,
                        checked,
                        id,
                        name
                      ),
                    })
                  );
                }}
                size="small"
                css={{ marginLeft: '16px' }}
              />
            }
            size="small"
            css={{
              alignItems: 'flex-start',
            }}
          />
        );
      });
  };

  return (
    <AccordionDetails css={{ padding: '8px 16px 16px 0' }}>
      {apiSearchKey && (
        <Box css={{ margin: '4px 0 16px 16px' }}>
          <SearchInput
            isLoading={isLoading}
            isDisabled={isErrored}
            value={query}
            onChange={({ target: { value } }) => {
              fetchFiltersDebounce(value);
              dispatch(updateFilterQueries({ [sectionKey]: value }));
            }}
            sectionKey={sectionKey}
          />
        </Box>
      )}
      <FilterModeButtonGroup sectionKey={sectionKey} />
      <Box
        css={{
          maxHeight: '400px',
          overflowY: 'scroll',
          overflowAnchor: 'none',
        }}
      >
        <FormGroup>{handleRenderItems()}</FormGroup>
      </Box>
    </AccordionDetails>
  );
}

export default FilterAccordionDetails;
