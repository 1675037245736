import isEmpty from 'lodash/isEmpty';
import { isFunction } from 'lodash';
import HTTP from '../../../core/http';
import * as actions from './actions';
import {
  buildId,
  QUERY_PARAM_LOOKUP,
} from '../../../lib/utils/queryParamUtils';
import { FILTER_NAMES } from '../../../containers/Filters/constants';
import { abort } from '../abort/operations';

export const buildSearchParams = ({
  searchQuery,
  apiSearchKey,
  determineSearchParams,
}) => {
  if (searchQuery) {
    if (isFunction(determineSearchParams)) {
      return determineSearchParams(searchQuery);
    }
    if (apiSearchKey) {
      return { [apiSearchKey]: searchQuery };
    }
  }
  return {};
};

export const fetchFilterItems =
  ({
    sectionKey,
    searchQuery,
    apiSearchKey,
    determineSearchParams,
    endpoint,
    params,
    parseData,
  }) =>
  async (dispatch, getState) => {
    await dispatch(abort(sectionKey));
    const {
      abort: { abortControllers },
      filters: { selectedFilterItems, filterModes },
    } = getState();
    const { signal } = abortControllers[sectionKey];
    const { [sectionKey]: benign, ...otherSelectedFilterItems } =
      selectedFilterItems;
    const searchParams = buildSearchParams({
      searchQuery,
      apiSearchKey,
      determineSearchParams,
    });
    const selectedFilterItemParams = Object.entries(
      otherSelectedFilterItems
    ).reduce((accumulator, [key, value]) => {
      const queryParams = QUERY_PARAM_LOOKUP[key];
      const filterMode = filterModes[key];
      const id = buildId(queryParams, filterMode);
      return isEmpty(value) || !FILTER_NAMES.includes(key)
        ? accumulator
        : {
            ...accumulator,
            [id]: Array.from(value.keys()).join(queryParams.delimiter || ','),
          };
    }, {});

    const { central_mode: centralMode, ...otherFilterItemParams } =
      selectedFilterItemParams;

    return HTTP.get(endpoint, {
      params: {
        ...params,
        ...searchParams,
        ...otherFilterItemParams,
        ...(centralMode !== 'single' && { central_mode: centralMode }),
      },
      signal,
    })
      .then((response) => {
        const mappedResults = parseData(response.data.results);

        dispatch(actions.updateFilterItems({ [sectionKey]: mappedResults }));
      })
      .catch(() => {
        if (signal && !signal.aborted) {
          throw new Error('Error while fetching filter items');
        }
      });
  };
