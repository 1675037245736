import { createTheme } from '@mui/material/styles';
import { hovered, offwhite, selected } from './colors';
import { SHOULD_NOT_SHOW_BANNER } from '../../containers/Banner/constants';

export const desktopCutoff = '1087px';
export const tabletCutoff = '768px';

export const mobileCutoff = '425px';
export const primaryFont = 'Proxima Nova';

export const secondaryFont = 'Titillium Web';
const fontWeightRegular = 400;
const fontWeightSemiBold = 600;
export const defaultColor = '#ffffff';
export const filterDrawerWidth = 400;
export const headerHeight = SHOULD_NOT_SHOW_BANNER ? 64 : 84;
const transitionDuration = 20;

const palette = {
  backgroundColor: { primary: '#f5f7f9', secondary: '#ffffff' },
  primary: {
    main: '#00799E',
    light: '#33a6c7',
    dark: '#006482',
  },
  secondary: {
    main: '#0b3b60',
    light: '#3b627f',
    dark: '#072943',
  },
  tertiary: {
    main: '#acf21f',
    light: '#e2ff5f',
    dark: '#77bf00',
  },
  warning: {
    main: '#cc3d00',
    light: '#ff9b70',
  },
  text: {
    primary: '#243745',
    secondary: '#65727b',
    disabled: '#99a1a8',
  },
  info: {
    main: '#0288D1',
  },
  divider: 'rgba(6, 26, 42, 0.12)',
  action: {
    active: 'rgba(6, 26, 42, 0.54)',
    hover: 'rgba(6, 26, 42, 0.04)',
    selected: 'rgba(6, 26, 42, 0.08)',
    disabled: 'rgba(6, 26, 42, 0.26)',
    disabledBackground: 'rgba(6, 26, 42, 0.12)',
    focus: 'rgba(6, 26, 42, 0.12)',
  },
};

export const theme = createTheme({
  palette,
  typography: {
    fontFamily: primaryFont,
    fontSize: 16,
    fontWeightLight: fontWeightRegular,
    fontWeightRegular,
    fontWeightMedium: fontWeightSemiBold,
    fontWeightBold: fontWeightSemiBold,
    h1: {
      fontFamily: secondaryFont,
      fontWeight: fontWeightRegular,
      fontSize: 96,
      letterSpacing: -1.5,
    },
    h2: {
      fontFamily: secondaryFont,
      fontWeight: fontWeightRegular,
      fontSize: 60,
      letterSpacing: -0.5,
    },
    h3: {
      fontFamily: secondaryFont,
      fontSize: 48,
    },
    h4: {
      fontFamily: secondaryFont,
      fontSize: 34,
      letterSpacing: 0.25,
      color: palette.text.primary,
    },
    h5: {
      fontFamily: secondaryFont,
      fontSize: 24,
    },
    h6: {
      fontFamily: secondaryFont,
      fontWeight: fontWeightSemiBold,
      fontSize: 20,
      letterSpacing: 0.15,
      color: palette.text.primary,
    },
    subtitle1: {
      fontFamily: secondaryFont,
      fontWeight: fontWeightSemiBold,
      fontSize: 16,
      letterSpacing: 0.15,
    },
    subtitle2: {
      fontFamily: secondaryFont,
      fontWeight: fontWeightSemiBold,
      fontSize: 14,
      letterSpacing: 0.1,
      color: '#243745',
    },
    subtitle3: {
      fontFamily: primaryFont,
      fontWeight: fontWeightSemiBold,
      fontSize: 16,
      lineHeight: 1.75,
      letterSpacing: 0.15,
    },
    subtitle4: {
      fontFamily: primaryFont,
      fontWeight: fontWeightSemiBold,
      fontSize: 14,
      lineHeight: 1.75,
      letterSpacing: 0.15,
    },
    body1: {
      fontSize: 16,
      letterSpacing: 0.15,
      color: palette.text.primary,
    },
    body2: {
      fontSize: 14,
      letterSpacing: 0.17,
      color: '#243745',
    },
    button: {
      fontWeight: fontWeightSemiBold,
      fontSize: 14,
      lineHeight: '24px',
      letterSpacing: '0.4px',
      textTransform: 'uppercase',
    },
    caption: {
      fontSize: 12,
      letterSpacing: '0.4px',
    },
    overline: {
      fontSize: 12,
      letterSpacing: '1px',
      textTransform: 'uppercase',
    },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: palette.text.secondary,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& label': {
            fontSize: 14,
          },
        },
        input: {
          fontSize: 14,
        },
        noOptions: {
          fontSize: 14,
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
        },
        columnHeaderTitle: {
          fontFamily: secondaryFont,
          lineHeight: '24px',
          letterSpacing: '0.17px',
        },
        cell: {
          '&:focus-within': {
            outline: 'none',
          },
        },
        row: {
          '&.even': {
            backgroundColor: offwhite,
          },
          '&:hover': {
            backgroundColor: hovered,
          },
          '&.Mui-selected': {
            backgroundColor: selected,
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          fontSize: 16,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        filledSuccess: {
          fontWeight: 400,
        },
        filledError: {
          fontWeight: 400,
        },
        filledInfo: {
          fontWeight: 400,
        },
      },
    },
    MuiAccordion: {
      defaultProps: {
        disableGutters: true,
      },
      styleOverrides: {
        root: {
          borderTop: '1px solid rgba(0, 0, 0, .125)',
          '&:before': {
            display: 'none',
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: palette.secondary.main,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        filled: {
          color: 'white',
        },
        deleteIcon: {
          color: '#0b3b60',
        },
      },
    },
    MuiSkeleton: {
      defaultProps: {
        animation: 'wave',
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: `${headerHeight}px !important`,
        },
      },
    },
    MuiTimelineDot: {
      styleOverrides: {
        root: {
          backgroundColor: palette.primary.main,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: palette.text.disabled,
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        grouped: {
          color: palette.text.secondary,
          borderColor: '#c6cace',
          '&:is(.selected, :hover)': {
            color: palette.primary.main,
            backgroundColor: '#ebf6fa',
          },
          fontSize: '14px',
        },
      },
    },
  },
});

export const getTransitionStyles = (isOpen, marginRight) => ({
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.easeIn,
    duration: transitionDuration,
  }),
  ...(isOpen && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: transitionDuration,
    }),
    marginRight,
  }),
});
