import { Box, Button, Typography } from '@mui/material';
import styled from '@emotion/styled/macro';
import { primaryText } from '../../styles/theme/colors';
import { defaultColor, primaryFont, tabletCutoff } from '../../styles/theme';

export const HeaderWrapper = styled.header`
  position: relative;
  color: ${primaryText};
  background-color: ${defaultColor};
  overflow: hidden;
  padding: 8px 16px;

  &::after {
    content: ' ';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 70px;
    background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    );
  }

  *:not(div) {
    /* In order for the blurred map overlay to not go over everything else */
    z-index: 10;
    position: relative;
  }

  .close-drawer {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 24px;
    background-color: white;
    padding: 10px;
    border-radius: 50%;
    line-height: 0;
    cursor: pointer;
    z-index: 20;
    transition: all 0.1s ease-in-out;

    i {
      color: ${primaryText};
    }

    &:hover {
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.15);
    }
  }

  .content-wrapper {
    display: inline-block;

    h1 {
      font-size: 1.4rem;
      font-weight: 200;
      margin: 0;

      strong {
        font-weight: 600;
      }
    }

    h2 {
      font-size: 1rem;
      margin: 0;
    }

    @media only screen and (max-width: ${tabletCutoff}) {
      h1 {
        font-size: 1.1em;
      }

      h2 {
        font-size: 1em;
      }

      .color-status-bar--text {
        font-size: 0.75rem;
      }
    }
  }
`;

export const BlackSquare = styled.span`
  display: inline-block;
  width: 5px;
  height: 5px;
  background-color: #061a2ade;
  margin-bottom: 2px;
`;

export const ContactActionButtonsWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: 8px;
  margin-bottom: 16px;
`;

export const ContactActionButton = styled(Button)`
  font-size: 13px;
  line-height: 22px;
  font-weight: 600;
  font-family: ${primaryFont};
  letter-spacing: 0.46px;
  text-decoration: none;
`;

export const ContentWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  margin-bottom: 4px;
`;

export const TooltipMessage = styled(Typography)`
  font-size: 10px;
  line-height: 14px;
  font-weight: 600;
  font-family: ${primaryFont};
  text-decoration: none;
  color: white;
`;
