import { subDays, addDays, format } from 'date-fns';

export const FILTERS_NAMES_TO_NOT_RESET = ['centralMode', 'batchId'];
export const FILTERS_NAMES_TO_NOT_COUNT = ['centralMode', 'batchId'];
const activeRangeStart = 'activeRangeStart';
const activeRangeEnd = 'activeRangeEnd';
const activeRangeStartEnd = 'activeRangeStartEnd';
export const FILTERS_TO_GROUP = {
  activeRangeStart: activeRangeStartEnd,
  activeRangeEnd: activeRangeStartEnd,
};
export const FILTERS_TO_COUNT_AS_ONE = [activeRangeStartEnd];
export const FILTER_SIBLINGS = {
  activeRangeStart: activeRangeEnd,
  activeRangeEnd: activeRangeStart,
};
const projectName = 'projectName';
const stores = 'stores';
export const FILTERS_WITH_MODES = [projectName, stores];
export const FILTER_NAMES = [
  'statuses',
  'staffingMethod',
  'divisions',
  projectName,
  'projectId',
  'assignmentId',
  'bannerRetailer',
  stores,
  'tdLinx',
  'brands',
  'states',
  'cityStates',
  'zipCodes',
  'jobTypes',
  'homeSystem',
  'associateType',
  'jyvers',
  activeRangeStart,
  activeRangeEnd,
  'callToAction',
  'centralMode',
  'batchId',
];

export const activeRangeStartDefault = subDays(new Date(), 14);
export const activeRangeEndDefault = addDays(new Date(), 60);
const centralModeDefault = 'single';

export const activeRangeStartFormatted = format(
  activeRangeStartDefault,
  'yyyy-MM-dd'
);
export const activeRangeEndFormatted = format(
  activeRangeEndDefault,
  'yyyy-MM-dd'
);

export const DEFAULT_SELECTED_FILTERS = {
  ...FILTER_NAMES.reduce(
    (accumulator, filterName) => ({
      ...accumulator,
      [filterName]: new Map(),
    }),
    {}
  ),
  activeRangeStart: new Map([
    [activeRangeStartFormatted, activeRangeStartFormatted],
  ]),
  activeRangeEnd: new Map([[activeRangeEndFormatted, activeRangeEndFormatted]]),
  centralMode: new Map([[centralModeDefault, centralModeDefault]]),
};

export const RESET_SELECTED_FILTERS = Object.entries(
  DEFAULT_SELECTED_FILTERS
).reduce(
  (accumulator, [key, value]) =>
    FILTERS_NAMES_TO_NOT_RESET.includes(key)
      ? accumulator
      : {
          ...accumulator,
          [key]: value,
        },
  {}
);

export const DEFAULT_FILTER_QUERIES = FILTER_NAMES.reduce(
  (accumulator, filterName) => ({ ...accumulator, [filterName]: '' }),
  {}
);

export const INCLUDE = 'include';
export const EXCLUDE = 'exclude';
export const FILTER_MODE_VARIANTS = [INCLUDE, EXCLUDE];

export const DEFAULT_FILTER_MODES = FILTERS_WITH_MODES.reduce(
  (accumulator, filterName) => ({ ...accumulator, [filterName]: INCLUDE }),
  {}
);

export const DATE_FNS_RANGE_FORMAT = 'yyyy-MM-dd';
