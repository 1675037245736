import { enqueueSnackbar } from 'notistack';
import HTTP from '../../../core/http';
import { URL } from '../../../core/config';
import { formatFilterCollectionToQueryParams } from '../../../lib/utils/queryParamUtils';
import { stringify } from '../../../lib/utils';
import {
  setBatchId,
  setBatchJobs,
  setIncludedSourceSystems,
  setIncludesAutoStaffedJob,
  setIsSelectAllPages,
  setIsSelectAllPagesLoading,
  setPageOfJobs,
  setSelectAllPageCount,
} from './actions';
import {
  DEFAULT_SORT_ORDERING,
  DEFAULT_STAFFING_WORK_PAGE_SIZE,
} from './constants';
import { abort } from '../abort/operations';
import { VARIANTS } from '../../../constants';
import { getSelectAllPagesCount } from '../../../services/batch';
import { setBatch } from '../dialog/actions';
import { getBatchDialog } from '../../../components/JobTable/utils';

export const validateQueryParamsLength = (queryParam, maxLength = 4000) => {
  const queryParamLength = queryParam.length;
  if (queryParamLength > maxLength) {
    enqueueSnackbar(
      'The filter limit has been reached. Please reduce your filters to resolve the error.',
      { variant: VARIANTS.error }
    );
  }
};

export const fetchPageOfJobs =
  ({
    batchId,
    page = 0,
    rowsPerPage = DEFAULT_STAFFING_WORK_PAGE_SIZE,
    sort = DEFAULT_SORT_ORDERING.join(','),
  }) =>
  async (dispatch, getState) => {
    const resource = 'fetchPageOfJobs';
    await dispatch(abort(resource));
    const {
      filters: { selectedFilterItems, filterModes },
      abort: { abortControllers },
    } = getState();
    const { signal } = abortControllers[resource];

    const { central_mode: centralMode, ...otherFilterItems } =
      formatFilterCollectionToQueryParams(selectedFilterItems, filterModes);

    const params = {
      page_size: rowsPerPage,
      page: page + 1,
      ordering: sort,
      ...(batchId ? { batch__in: batchId } : { ...otherFilterItems }),
      ...(centralMode !== 'single' && { central_mode: centralMode }),
    };

    const paramEntries = Object.entries(params);
    const data = stringify(paramEntries);
    validateQueryParamsLength(data);
    const endpoint = URL.jobs;
    return HTTP.get(endpoint, {
      params,
      signal,
    })
      .then((response) => {
        dispatch(setPageOfJobs(response.data.results));
      })
      .catch(() => {
        if (signal && !signal.aborted) {
          throw new Error('Error while fetching page of jobs');
        }
      });
  };

export const fetchPageOfJobsCount = (batchId) => async (dispatch, getState) => {
  const resource = 'fetchPageOfJobsCount';
  await dispatch(abort(resource));
  const {
    filters: { selectedFilterItems, filterModes },
    abort: { abortControllers },
  } = getState();
  const { signal } = abortControllers[resource];

  const { central_mode: centralMode, ...otherFilterItems } =
    formatFilterCollectionToQueryParams(selectedFilterItems, filterModes);

  const params = {
    ...(batchId ? { batch__in: batchId } : { ...otherFilterItems }),
    ...(centralMode !== 'single' && { central_mode: centralMode }),
  };

  const paramEntries = Object.entries(params);
  const data = stringify(paramEntries);
  validateQueryParamsLength(data);
  const endpoint = `${URL.jobs}count`;
  return HTTP.get(endpoint, {
    params,
    signal,
  })
    .then((response) => response.data.count)
    .catch(() => {
      if (signal && !signal.aborted) {
        throw new Error('Error while fetching jobs count');
      }
    });
};

export const fetchSelectAllPagesCount =
  ({ hasFilters, editBatchId, rows, onSelectAllClick }) =>
  async (dispatch, getState) => {
    const resource = 'fetchSelectAllPagesCount';
    await dispatch(abort(resource));
    const {
      filters: { selectedFilterItems, filterModes },
      abort: { abortControllers },
    } = getState();
    const { centralMode, ...otherSelectedFilterItems } = selectedFilterItems;
    const { signal } = abortControllers[resource];
    dispatch(setIsSelectAllPages(true));
    dispatch(setIsSelectAllPagesLoading(true));
    getSelectAllPagesCount(
      {
        centralMode,
        ...(hasFilters ? otherSelectedFilterItems : {}),
        batchId: new Map([[editBatchId, editBatchId]]),
      },
      filterModes,
      signal
    )
      .then((data) => {
        dispatch(setBatchJobs([]));
        dispatch(setIsSelectAllPagesLoading(false));
        dispatch(setBatchId(data.id));
        dispatch(setIncludesAutoStaffedJob(data.includes_auto_staffed_jobs));
        dispatch(setSelectAllPageCount(data.count));
        dispatch(setIncludedSourceSystems(data.included_source_systems));
      })
      .catch((error) => {
        if (signal && !signal.aborted) {
          dispatch(setIsSelectAllPages(false));
          dispatch(setIsSelectAllPagesLoading(false));
          dispatch(setBatch(getBatchDialog(rows.length, onSelectAllClick)));
          enqueueSnackbar(
            error.detail || 'Failed to select all currently filtered items.',
            { variant: VARIANTS.error }
          );
        }
      });
  };
