/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import axios from 'axios';
import Accordion from '@mui/material/Accordion';
import { ExpandMore } from '@mui/icons-material';
import { format, startOfDay } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import {
  displayAvailability,
  displayCommuteMiles,
  displayMaxDailyHours,
} from 'containers/Associate/utils';
import { AvailabilityTypography } from 'containers/Associate/components/style';
import AssociateAvailabilityInfoRow from 'containers/Associate/components/AssociateAvailabilityInfoRow';
import { getAssociatePreferences } from '../../../services/user';
import Error from './Error';
import {
  formatTimeRange,
  scrollIntoView,
  transformAvailabilityData,
} from '../../../lib/utils';
import LoadingSpinner from './LoadingSpinner';

function WorkPreferencesCollapsableHeader({ associate, job, selectedDay }) {
  const [isLoading, setIsLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [workPreferences, setWorkPreferences] = useState(null);
  const [transformedPreferences, setTransformedPreferences] = useState(null);
  const [workPreferencesError, setWorkPreferencesError] = useState(null);

  const handleDayChange = (date) => {
    setIsLoading(true);
    setWorkPreferencesError(null);
    if (date) {
      const effectiveDate = zonedTimeToUtc(
        startOfDay(date),
        job.store_location_timezone
      );
      getAssociatePreferences({
        userId: associate.id,
        effectiveDate,
      })
        .then((preferences) => {
          setIsLoading(false);

          const { day_availability: daily_availability, ...rest } = preferences;
          const updatedPreferences = { ...rest, daily_availability };
          setTransformedPreferences(
            transformAvailabilityData(updatedPreferences)
          );

          setWorkPreferences(preferences);
        })
        .catch((error) => {
          setIsLoading(false);
          if (!axios.isCancel(error)) {
            setWorkPreferencesError(
              error.message ||
                'There was a problem retrieving the Associate record'
            );
          }

          setWorkPreferencesError(error);
        });
    }
  };

  useEffect(() => {
    handleDayChange(selectedDay);
  }, [selectedDay]);

  useEffect(() => {
    scrollIntoView(expanded, 'work-preferences-accordion-details', 400);
  }, [expanded]);

  return (
    <Accordion
      expanded={expanded}
      square
      onChange={() => setExpanded(!expanded)}
      css={{ border: 0, boxShadow: 'unset' }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore fontSize="medium" />}
        aria-controls="panel1b-content"
        id="panel1b-header"
      >
        <Box id="work-preferences">
          <Typography variant="subtitle1">Work Preferences</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails
        id="work-preferences-accordion-details"
        css={{ padding: '16px' }}
      >
        {isLoading && <LoadingSpinner id="associate-preferences-loading" />}

        {workPreferencesError && !isLoading ? (
          <Error
            id="associate-preferences-error"
            doRetry={() => {
              setWorkPreferencesError(null);
              handleDayChange(selectedDay);
            }}
          />
        ) : (
          <>
            {workPreferences && !isLoading ? (
              <>
                <AssociateAvailabilityInfoRow label="Home store only">
                  <AvailabilityTypography id="home-store" variant="body1">
                    {displayAvailability(workPreferences.my_home_store_only)}
                  </AvailabilityTypography>
                </AssociateAvailabilityInfoRow>
                {!workPreferences.my_home_store_only && (
                  <AssociateAvailabilityInfoRow label="Willing to drive">
                    <AvailabilityTypography
                      id="max-commute-miles"
                      variant="body1"
                    >
                      {displayCommuteMiles(
                        workPreferences.maximum_commute_miles
                      )}
                    </AvailabilityTypography>
                  </AssociateAvailabilityInfoRow>
                )}

                {!workPreferences.my_home_store_only && (
                  <AssociateAvailabilityInfoRow label="Travel and stay overnight">
                    <AvailabilityTypography id="overnight-stay" variant="body1">
                      {displayAvailability(
                        workPreferences.allow_overnight_stay
                      )}
                    </AvailabilityTypography>
                  </AssociateAvailabilityInfoRow>
                )}

                <AssociateAvailabilityInfoRow label="Preferred hours a week">
                  <AvailabilityTypography
                    id="preferred-weekly-hours"
                    variant="body1"
                  >
                    {`${workPreferences.preferred_weekly_hours} hours`}
                  </AvailabilityTypography>
                </AssociateAvailabilityInfoRow>

                <AssociateAvailabilityInfoRow label="Maximum hours per day">
                  <AvailabilityTypography id="max-daily-hours" variant="body1">
                    {displayMaxDailyHours(
                      workPreferences.preferred_daily_hours
                    )}
                  </AvailabilityTypography>
                </AssociateAvailabilityInfoRow>
                {workPreferences.day_availability.length === 0 ? (
                  <Typography
                    variant="subtitle2"
                    id="no-work-preferences-div"
                    css={{
                      display: 'flex',
                      justifyContent: 'center',
                      paddingTop: '10px',
                      paddingBottom: '10px',
                    }}
                  >
                    No availability for this associate
                  </Typography>
                ) : (
                  transformedPreferences.map((availabilityData) => (
                    <AssociateAvailabilityInfoRow
                      key={availabilityData.start}
                      id={`availability-${availabilityData.day}`}
                      label={
                        availabilityData &&
                        format(new Date(0, 0, availabilityData.day), 'EEEE')
                      }
                    >
                      <Box
                        css={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'end',
                        }}
                      >
                        {availabilityData.time_ranges.length === 0 ? (
                          <AvailabilityTypography variant="body1">
                            Not Available
                          </AvailabilityTypography>
                        ) : (
                          availabilityData.time_ranges.map((time, index) => (
                            <AvailabilityTypography
                              // eslint-disable-next-line react/no-array-index-key
                              key={`time-slot-${index}`}
                              id={`${availabilityData.day}-time-slot-${index}`}
                              variant="body1"
                            >
                              {formatTimeRange(time)}
                            </AvailabilityTypography>
                          ))
                        )}
                      </Box>
                    </AssociateAvailabilityInfoRow>
                  ))
                )}
              </>
            ) : null}
            {!workPreferences && !isLoading ? (
              <Typography
                variant="subtitle2"
                id="no-work-preferences-div"
                css={{
                  display: 'flex',
                  justifyContent: 'center',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                }}
              >
                No work preferences for this associate
              </Typography>
            ) : null}
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
}

export default WorkPreferencesCollapsableHeader;
