import { isObject } from 'lodash';
import {
  EXCLUDE,
  FILTER_NAMES,
  INCLUDE,
} from '../../containers/Filters/constants';

export const QUERY_PARAM_LOOKUP = {
  activeRangeStart: {
    apiId: 'local_start_after__gte',
  },
  activeRangeEnd: {
    apiId: 'local_start_after__lte',
  },
  statuses: {
    apiId: 'status__in',
  },
  staffingMethod: {
    apiId: 'staffing_method__in',
  },
  brands: {
    apiId: 'client__in',
  },
  states: {
    apiId: 'geo_store_state__in',
  },
  jobTypes: {
    apiId: 'advantage_type_of_work__in',
  },
  homeSystem: {
    apiId: 'advantage_source_system_id__in',
  },
  jyvers: {
    apiId: 'jyver',
  },
  stores: {
    apiIdParts: {
      prefix: 'store_primary_self_identity',
      [INCLUDE]: '__in',
      [EXCLUDE]: '__not_in',
    },
  },
  tdLinx: {
    apiId: 'td_linx_id__in',
  },
  projectName: {
    apiIdParts: {
      prefix: 'name',
      [INCLUDE]: '__in_pipe',
      [EXCLUDE]: '__not_in_pipe',
    },
    delimiter: '|',
  },
  divisions: {
    apiId: 'division_id__in',
  },
  projectId: {
    apiId: 'external_project_identifier__in',
  },
  assignmentId: {
    apiId: 'external_identifier__in',
  },
  bannerRetailer: {
    apiId: 'store_chain__in',
  },
  zipCodes: {
    apiId: 'geo_store_postal_code__in',
  },
  cityStates: {
    apiId: 'geo_store_city_state__in',
    delimiter: '|',
  },
  callToAction: {
    apiId: 'call_to_action__in',
  },
  associateType: {
    apiId: 'associate_type__in',
  },
  centralMode: {
    apiId: 'central_mode',
  },
  batchId: {
    apiId: 'batch__in',
  },
};

const formatValueToQueryParams = (filters, key) => {
  const { delimiter } = QUERY_PARAM_LOOKUP[key];
  return Array.from(filters.keys()).join(delimiter || ',');
};

export const buildId = (queryParams, filterMode) => {
  if (isObject(queryParams.apiIdParts)) {
    const {
      apiIdParts: { prefix },
    } = queryParams;
    return `${prefix}${queryParams.apiIdParts[filterMode]}`;
  }
  return queryParams.apiId;
};

export const formatFilterCollectionToQueryParams = (
  selectedFilterItems,
  filterModes
) =>
  Object.entries(selectedFilterItems).reduce((accumulator, [key, filters]) => {
    const filterKeys = Array.from(filters.keys());
    const allFilterKeysAreFalsy = filterKeys.every((filter) => !filter);
    if (allFilterKeysAreFalsy || !FILTER_NAMES.includes(key)) {
      return accumulator;
    }
    const filterMode = filterModes[key];
    const id = buildId(QUERY_PARAM_LOOKUP[key], filterMode);
    return {
      ...accumulator,
      [id]: formatValueToQueryParams(filters, key),
    };
  }, {});
