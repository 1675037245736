import React, { useState } from 'react';
import { Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ClearIcon from '@mui/icons-material/Clear';

function ClearFiltersCounter({
  onClear,
  label = '',
  numberOfActiveFilters,
  sectionKeys,
}) {
  const [isHoverClearAll, setIsHoverClearAll] = useState(false);
  const theme = useTheme();
  const joinedSectionKeys = sectionKeys.join('-');
  const dataTestId = `filter-section-${joinedSectionKeys}-clear-all`;
  const onMouseEnter = () => {
    setIsHoverClearAll(true);
  };
  const onMouseLeave = () => {
    setIsHoverClearAll(false);
  };
  const onClick = (event) => {
    event.stopPropagation();
    onClear();
  };
  const size = '28px';
  const button = (id) => (
    <Button
      css={{
        lineHeight: '1',
        minWidth: size,
        width: size,
        height: size,
        padding: 0,
        marginLeft: '8px',
        color: theme.palette.text.primary,
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      color="tertiary"
      variant="contained"
      data-testid={id}
    >
      {isHoverClearAll ? <ClearIcon size="small" /> : numberOfActiveFilters}
    </Button>
  );
  if (label) {
    return (
      <>
        <Button
          variant="text"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onClick={onClick}
          css={{
            color: 'white',
          }}
        >
          {label}
        </Button>
        {button(dataTestId)}
      </>
    );
  }

  return button(dataTestId);
}

export default ClearFiltersCounter;
