const mainListOfText = [
  'Unstaffed or On Hold',
  'Between now and 90 days from now',
  'Work Type: Demonstration, Demo - Adult Beverage',
];

const geographyAndBannerSharingDescription =
  'For assignments to be AI Assist eligible and staffed, specific requirements must be met.';
const homeStoreOnlyDescription =
  'For assignments to be eligible for AI assisted staffing, specific requirements must be met. Only assignments that meet these requirements will be shown.';
const thirtyMileRadiusText =
  'Teammates are within a 30-mile radius of the store';

export const autoStaffingDialogs = {
  auto_home_store_only: {
    dialog: {
      title: 'AI Assisted Staffing - Home Store',
      description: homeStoreOnlyDescription,
      subTitles: [
        {
          title: '',
          listOfText: [
            ...mainListOfText,
            'Location has at least 1 Teammate that is active with that home store',
          ],
        },
      ],
      revisedDate: '',
      cacheKey: 'aiAssistedStaffingHomeStoreOnlyDialog',
    },
  },
  auto_beyond_home_store: {
    dialog: {
      title: 'AI Assisted Staffing - Geography',
      description: geographyAndBannerSharingDescription,
      subTitles: [
        {
          title: 'Assignment Eligibility:',
          listOfText: mainListOfText,
        },
        {
          title: 'Teammate Eligibility:',
          listOfText: [thirtyMileRadiusText, 'Within banner only'],
        },
      ],
      revisedDate: '',
      cacheKey: 'aiAssistedStaffingGeographyDialog',
    },
  },
  auto_banner_sharing: {
    dialog: {
      title: 'AI Assisted Staffing - Banner Share',
      description: geographyAndBannerSharingDescription,
      subTitles: [
        {
          title: 'Assignment Eligibility:',
          listOfText: mainListOfText,
        },
        {
          title: 'Teammate Eligibility:',
          listOfText: [thirtyMileRadiusText, 'No banner restrictions'],
        },
      ],
      revisedDate: '',
      cacheKey: 'aiAssistedStaffingBannerShareDialog',
    },
  },
};
